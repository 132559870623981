import React from 'react';
import Link from 'gatsby-link';
import './style.sass';
import home from '../../img/home.svg';
import blog from '../../img/file-text.svg';
import contact from '../../img/mail.svg';
import subscribe from '../../img/star.svg';
import './script.js'

const Navbar = () => (
  <nav className="navbar">
    <div className="navbar-logo">
      <Link to="/">
        <div className="logo">
          <div className="smiley">
            <div className="eyes">
              <div className="eye"></div>
              <div className="eye"></div>
            </div>
            <div className="mouth"></div>
          </div>
          <div className="wordmark">
            Simon Koelewijn
            </div>
        </div>
      </Link>
    </div>
    <div className="navbar-items">
      <Link activeClassName="active" className="navbar-item" to="/" >
        <img src={home} alt="Home" />
        <span>Home</span>
      </Link>
      <Link activeClassName="active" className="navbar-item" to="/artikelen">
        <img src={blog} alt="Artikelen" />
        <span>Artikelen</span>
      </Link>
      <Link activeClassName="active" className="navbar-item" to="/contact">
        <img src={contact} alt="Contact" />
        <span>Contact</span>
      </Link>
      <Link activeClassName="active" className="navbar-item subscribe" to="/abonneren">
        <img src={subscribe} alt="Abonneren" />
        <span>Abonneren</span>
      </Link>
    </div>

  </nav >
);

export default Navbar;
