import React from "react";
import Link from "gatsby-link";
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import { graphql } from 'gatsby'

export default class BlogPage extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (

      <Layout>
        <Helmet
          titleTemplate="%s | Simon Koelewijn"
        >
          <title>Artikelen</title>
          <meta name="description" content="Artikelen van Simon Koelewijn over optimalisatie, analytics &amp; UX." />
        </Helmet>
        <section className="section">
          <div className="container container-small">
            <h1>Artikelen</h1>
            {posts
              .map(({ node: post }) => (
                <article className="post" key={post.id}>
                  <Link to={post.fields.slug}>
                    <small>{post.frontmatter.date}</small>
                    <h2>
                      {post.frontmatter.title}
                    </h2>
                    <p>
                      {post.excerpt}
                    </p>
                  </Link>
                </article>
              ))}
          </div>
        </section>
      </Layout>

    );
  }
}

export const pageQuery = graphql`
  query BlogQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { frontmatter: { templateKey: { eq: "blog-post" } }}
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "dddd D MMMM YYYY", locale: "nl" )
          }
        }
      }
    }
  }
`